<template>
  <div class="detailed-targeting">
    <div class="detailed-targeting-content">
      <div v-for="(item, index) in detailedTargeting"
           :key="index"
           class="detailed-target-block-wrapper">
        <div v-if="indexing" class="detailed-targeting-content-title">
          <p>Detailed targeting {{index + 1}}</p>
        </div>
        <TargetContentDetailedBlock
          :type="'included'"
          v-for="(incl, index) in item.inclusion_targeting"
          :index="index" :key="index" :targeting-data="incl"/>
        <TargetContentDetailedBlock
          :type="'excluded'"
          :targeting-data="item.exclusion_targeting"/>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

import TargetContentDetailedBlock from './TargetContentDetailedBlock.vue'
export default Vue.extend({
  name: 'TargetContentDetailed',
  components: { TargetContentDetailedBlock },
  props: ['detailedTargeting', 'indexing']
})
</script>
<style scoped lang="scss">
.detailed-targeting {
  &-content {
    margin-bottom: 10px;
    &-title {
      margin-bottom: 20px;
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        background: #E0F4FF;
        border-radius: 15px;
        display: inline-flex;
        padding: 0 10px;
      }
    }
    .detailed-target-block-wrapper {
      margin-bottom: 20px;
      background: #F9F9F9;
      padding: 15px;
    }
  }
}
</style>
