















































import Vue from 'vue'
import CountryFlag from 'vue-country-flag'

export default Vue.extend({
  name: 'TargetContentLocationNew',
  components: {
    CountryFlag
  },
  props: {
    is_included: {
      type: Boolean,
      required: true
    },
    formattedLocations: {
      type: Object,
      required: true
    }
  }
})
