<template>
  <div v-if="targetingData && Object.values(targetingData).flat().length" class="detailed-target-block d-flex"
       :class="{'included': type === 'included', 'excluded': type === 'excluded'}"
       :style="{'padding': index === 0 ? '0 0 15px 0' : '15px 0'}">
    <div class="detailed-target-block-title">
      <div class="detailed-target-block-title-item"
           v-if="type === 'included'">
        <div class="icon">
          <img src="../../../../assets/ads-manager/green-plus.svg"
               alt="">
        </div>
        <div class="text">
          <p>
            <span v-if="index === 0">Include people who match:</span>
            <span v-else>and must also match</span>
          </p>
        </div>
      </div>
      <div class="detailed-target-block-title-item"
           v-if="type === 'excluded'">
        <div class="icon">
          <img src="../../../../assets/ads-manager/red-minus.svg"
               alt="">
        </div>
        <div class="text">
          <p>Exclude people who match:</p>
        </div>
      </div>
    </div>
    <div class="detailed-target-block-content">
      <div v-for="(item, index) in Object.values(targetingData).flat()"
           :key="index" class="tag"
           :class="{'included-tag': type === 'included', 'excluded-tag': type === 'excluded'}">
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'TargetContentDetailedBlock',
  props: [
    'index',
    'type',
    'targetingData'
  ]
}
</script>
<style scoped lang="scss">
.detailed-target-block {
  &-title {
    width: 120px;
    padding: 0 10px 0 0;
    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .icon {
        width: 16px;
        display: flex;
        justify-content: flex-start;
        margin: 0 8px 0 0;
        img {
          width: 16px;
        }
      }
      .text {
        p {
          color: #444444;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  &-content {
    width: calc(100% - 120px);
    .tag {
      box-sizing: border-box;
      border-radius: 5px;
      padding: 3px 10px;
      margin-right: 8px;
      margin-bottom: 8px;
      display: inline-flex;
      cursor: pointer;
      font-size: 14px;
      line-height: 19px;
      &-text {}
      &-close-icon {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        img {
          width: 100%;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .tag.included-tag {
      background: #e0f4ff;
      border: 1px solid #b1d3e5;
    }
    .tag.excluded-tag {
      background: #FFE3E2;
      border: 1px solid #FD6F6E;
    }
  }
}
.detailed-target-block.included {
  // border: 2px solid green
}
.detailed-target-block.excluded {
  // border: 2px solid red
  padding-top: 15px;
}
</style>
