



































import Vue from 'vue'
import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'

export default Vue.extend({
  components: { Button },
  props: {
    title: String,
    hoverDashboard: Boolean,
    hoverAllAds: Boolean,
    hoverAdsReporting: Boolean
  },

  methods: {
    dashboard () {
      this.$router.push({
        params: {
          campaign_id: this.$route.params.campaign_id
        },
        name: 'dashboard'
      })
    },

    allAds () {
      this.$router.push({
        params: {
          campaign_id: this.$route.params.campaign_id
        },
        name: 'AllAds'
      })
    }
  }
})
