<template>
  <div class="target-content-placements">
    <div v-if="indexing" class="target-content-placements-title">
      <p>Placement</p>
    </div>
    <div v-if="placements && Object.keys(placements).length > 0 && Object.keys(placements.positions) && Object.keys(placements.positions).length > 0">
      <div class="devices">
        <p v-for="(device, index) in placements['devices']"
           :key="index">
          <span class="name">{{device}}</span>
          <span class="delimiter"
                v-if="placements.devices.length - 1 !== index ">|</span>
        </p>
      </div>
      <div class="positions">
        <div class="positions-row d-flex"
             v-for="(platform, index) in placements.platforms" :key="index">
          <div class="platform">
            <p>{{platform}}:</p>
          </div>
          <div class="position d-inline-flex flex-wrap">
            <p v-for="(position, index) in placements.positions[platform]"
               :key="index">
              {{position}}
              <span v-if="placements.positions[platform].length - 1 !== index">,&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-message"
         v-if="placements.length === 0">
      Automatic
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'TargetContentPlacement',
  props: ['placements', 'indexing']
})
</script>
<style scoped lang="scss">
  .target-content-placements {
    background: #F9F9F9;
    border-radius: 5px;
    padding: 15px 15px 20px 15px;
    &-title {
      display: inline-flex;
      border-radius: 15px;
      background: #E0F4FF;
      margin-bottom: 20px;
      p {
        font-size: 14px;
        color: #212529;
        line-height: 1.5;
        padding: 0 10px;
      }
    }
    .devices {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;
      padding: 0 5px;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        .delimiter {
          padding: 0 10px;
          color: #D2D6DE;
          font-weight: 400;
        }
      }
    }
    .positions {
      padding: 0 5px;
      &-row {
        .platform {
          width: 70px;
          p {
            text-transform: capitalize;
            color: #212529;
            font-size: 15px;
            line-height: 16px;
          }
        }
        .position {
          p {
            color: #808181;
            font-size: 15px;
            line-height: 16px;
            text-transform: capitalize;
          }
        }
      }
      &-row:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .empty-message {
    background: #F9F9F9;
    padding: 0 10px;
  }
</style>
